.card {
    background-color: transparent;
    /* margin-inline: 1.5rem; */
    /* padding: 30px; */
    width: 100%; 
    height: 80%;
    perspective: 1000px;
    flex: 1;
    /* float:left;  */
  }

  .card-display{
    /* position: relative; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .card-inner {
    position: relative;

    justify-content: center;
    margin: auto;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
    animation: 3s ease-out 0s 1 slideInLeft;
    /* animation: 1s normal 0s 1 slideInLeft; */
  }
  .card-front, .card-back{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    /* border-radius: 10px; */
    
  }
    img{
      max-width: 100%;
      max-height: 100%;
      border-radius: 10px;
      /* height: auto; */
      /* width: calc((279 / 500) * 100%); */
      /* height: calc((279 / 500) * 100%); */
   
    }
  .card .card-inner {
    transform: rotateY(180deg);
}
  .card-back {
    
    /* transform: rotateY(180deg); */
  }
   .card-front {
    
    transform: rotateY(180deg);
  }

  .card-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex:1;
    width: 100%;
    height: 100%;

  }
  @keyframes slideInLeft {
    0% {

    }
    100% {
        transform: rotateY(2340deg);
   
    }
}
.dual-first {
  /* transform: translateX(50px); */
}
.dual-second{
  /* position: absolute; */
  /* top: -20%;
  left: -20%; */
  /* transform: translateY(-20px); */
  /* transform: scale(50%); */
}