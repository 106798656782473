.App {
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.particles{
  position: absolute;
  width: 100vw;
  height: 100vh;

}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-Main {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100%;
  height: 100%;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.but {
  max-height: 2rem;
  width: 100%;
  padding: 20px;
  top: 0;
  z-index: 100;
}
.but button{
  
  max-height: 2rem;
  font-size: 10px;
  margin-inline: 30px;
}

.cast{
  right: 5%;
  position: absolute;
}